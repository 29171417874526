import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Slide.css";

const photos = [
  {
    name: "Mirabella, Noida",
    // name: "Mirabella",
    description: "Consultancy of RCC Structures",
    // description: "RCC Structures",
    url: require("./Images/01 Mirabella, Noida - AA & Associates.jpg"),
  },
  {
    // name: "ERA Auditorium, Iraq",
    name: "Majidi Mall, Iraq",
    description: "Consultancy of MS Structures",
    // description: "MS Structures",
    url: require("./Images/02 Majidi Mall at Sulemaniya, Iraq - AA & Associates.jpg"),
  },
  {
    name: "Bhoomiyug Appartments, Kamasettn, Vasai ",
    // name: "Bhoomiyug Appartments",
    description: "Consultancy of Clusters",
    // description: "Clusters",
    url: require("./Images/03 Bhoomiyug Appartments, Kaman, Vasai - AA & Associates.jpg"),
  },
  // {
  //   name: "Heera Bungalow, Satara",
  //   // name: "Heera Bungalow",
  //   description: "Post-Tensioning Consultancy",
  //   // description: "Post-Tensioning",
  //   url: require("./Images/04 Heera Bungalow, Satara - AA & Associates.jpg"),
  // },
  {
    name: "Club House, Naupada, Orissa",
    // name: "Club House",
    // description: "Consultancy of RCC Structures",
    description: "Public Buildings",
    url: require("./Images/05 Club House, Naupada, Orissa - AA & Associates.jpg"),
  },
  {
    name: "Times OOH Hoarding on CSI Airport Elevated Road, Mumbai",
    // name: "Times OOH Hoarding",
    description: "Consultancy of Hoardings",
    // description: "Hoardings",
    url: require("./Images/06 Times OOH Hoarding on CSI Airport Elevated Road, Mumbai - AA & Associates.jpg"),
  },
  {
    name: "RRBC Mall, Hindupur",
    // description: "Commmercial Buildings",
    description: "Post-Tensioning Consultancy",
    url: require('./Images/07 RRBC Mall, Hindupur - AA & Associates.jpg')
  },
];

class Slide extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      className: "slides",
    };
    return (
      <div className="slide">
        <Slider {...settings}>
          {photos.map((photo) => {
            return (
              <div>
                <img src={photo.url} alt="Images" />
                <div className="slideDescription">
                  <h3>{photo.description}</h3>
                  <h4>{photo.name}</h4>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default Slide;
