import React from "react";
import "./AllProjects.css";

const CommercialPics = [
  {
    url: require("./Projects-Images/2-Commercial-Buildings/01 Atak Hotel, Pune - AA & Associates.jpg"),
    name: "Atak Hotel, Pune",
  },
  {
    url: require("./Projects-Images/2-Commercial-Buildings/02-Sunil Arcade IT Park, Mira Road - AA & Associates.jpg"),
    name: "Sunil Arcade IT Park, Mira Road",
  },
  {
    url: require("./Projects-Images/2-Commercial-Buildings/03 Mall, Hindupur - AA & Associates.jpg"),
    name: "RRBC Mall, Hindupur",
  },
  {
    url: require("./Projects-Images/2-Commercial-Buildings/04 Wallfort House Commercial Complex at Goregaon - AA & Associates.jpg"),
    name: "Wallfort House, Goregaon",
  },
  {
    url: require("./Projects-Images/2-Commercial-Buildings/05-Adkure Pharmaceutical Factory, Wada - AA & Associates.jpg"),
    name: "Adkure Pharmaceutical Factory, Wada",
  },
  {
    url: require("./Projects-Images/2-Commercial-Buildings/06 Trivedi Centre, Mira Road - AA & Associates.jpg"),
    name: "Trivedi Centre, Mumbai",
  },
  {
    url: require("./Projects-Images/2-Commercial-Buildings/07 Hira Office, Satara - AA & Associates.jpg"),
    name: "Hira Office, Satara",
  },
  {
    url: require("./Projects-Images/2-Commercial-Buildings/08 Parini Complex, Mumbai - AA & Associates.jpg"),
    name: "Parinee Complex, Mumbai",
  },
  {
    url: require("./Projects-Images/2-Commercial-Buildings/09 Showroom at Virar - AA & Associates.jpg"),
    name: "Showroom at Virar",
  },
];

export default function Commercial() {
  return (
    <div className="projectsContainer flat" id="CB">
      <h2>Commercial Projects</h2>
      <div className="projectsWrap">
        {CommercialPics.map((CommercialPic) => {
          return (
            <div className="projects">
              <div className="projectsPhotos">
                <img src={CommercialPic.url} alt="Projects" />
                <div className="projectsNames">
                  <div className="projectsNamesContent">
                    <h4 className="projectsText">{CommercialPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
