import React from "react";
import "./AllProjects.css";

const VillasPics = [
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/01 Kenmark d'souza bungalow at Gorai - AA & Associates.jpg"),
    name: "Kenmark d'souza bungalow, Gorai",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/02 Uttam Niwas, Mira Road - AA & Associates.jpg"),
    name: "Uttam Niwas, Mumbai",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/03 Bungalow at Solapur - AA & Associates.jpg"),
    name: "Bungalow at Solapur",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/04 Duplex Bungalow, Ramanagaram - AA & Associates.jpg"),
    name: "Duplex Bungalow, Ramanagaram",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/05 Dgains Studio Appartment - AA & Associates.jpg"),
    name: "Dgains Studio Appartment",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/06 Bungalow at Bharuch - AA & Associates.jpg"),
    name: "Bungalow at Bharuch",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/07 Bungalow at Gulbarga - AA & Associates.jpg"),
    name: "Bungalow at Gulbarga",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/08 Mane Ji Bungalow at Solapur - AA & Associates.jpg"),
    name: "Mane Ji Bungalow, Solapur ",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/41 Residential Villas/09 Raipur Bungalow - AA & Associates.jpg"),
    name: "Raipur Bungalow",
  },
];
// const SataraPics = [
//   {
//     url: require("./Projects-Images/4-Residential-Villas/42 Bungalow at Satara/01-Hira Bungalow, Satara(Front View) - AA & Associates.jpg"),
//     name: "Front View",
//   },
//   {
//     url: require("./Projects-Images/4-Residential-Villas/42 Bungalow at Satara/02-Hira Bungalow, Satara(Diagonal View) - AA & Associates.jpg"),
//     name: "Diagonal View",
//   },
//   {
//     url: require("./Projects-Images/4-Residential-Villas/42 Bungalow at Satara/03-Hira Bungalow, Satara(Rear View) - AA & Associates.jpg"),
//     name: "Rear View",
//   },
// ];
const KotaPics = [
  {
    url: require("./Projects-Images/4-Residential-Villas/43 Bungalow at Kota, Rajasthan/01 Bungalow, Rajasthan - AA & Associates.jpg"),
    name: "Day View",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/43 Bungalow at Kota, Rajasthan/02 Bungalow, Rajasthan - AA & Associates.jpg"),
    name: "Night View",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/43 Bungalow at Kota, Rajasthan/03 Bungalow, Rajasthan - AA & Associates.jpg"),
    name: "Rear View",
  },
];
const AmazeumParkPics = [
  {
    url: require("./Projects-Images/4-Residential-Villas/44 Amazeum Park at Curiocity Mall, Pune/01 Play House - AA & Associates.jpg"),
    name: "Play House",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/44 Amazeum Park at Curiocity Mall, Pune/02 Fort View - AA & Associates.jpg"),
    name: "Fort View",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/44 Amazeum Park at Curiocity Mall, Pune/03 Fort Section View - AA & Associates.jpg"),
    name: "Section View",
  },
];
const DgainsPics = [
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/01 Dgains Layout View - AA & Associates.jpg"),
    name: "Dgains Layout View",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/02 Elenza Homes - AA & Associates.jpg"),
    name: "Elenza Homes",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/03 Row Bungalows - AA & Associates.jpg"),
    name: "Row Bungalows",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/04 Live Kitchen - AA & Associates.jpg"),
    name: "Live Kitchen",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/05 Kids Play Area - AA & Associates.jpg"),
    name: "Kids Play Area",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/06 Fountain View - AA & Associates.jpg"),
    name: "Fountain View",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/07 Twin Bungalows - AA & Associates.jpg"),
    name: "Twin Bungalows",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/08 Spa & Gym - AA & Associates.jpg"),
    name: "Spa & Gym",
  },
  {
    url: require("./Projects-Images/4-Residential-Villas/45 Elegant-Dgains Homes, Karjat/09 Swimming Pool - AA & Associates.jpg"),
    name: "Swimming Pool",
  },
];


export default function Villas() {
  return (
    <div id="RV">
      <div className="projectsContainer flat">
        <h2>Villas / Bungalows</h2>
        <div className="projectsWrap">
          {VillasPics.map((VillasPic) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={VillasPic.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{VillasPic.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

        {/*-------------------- Hira Bungalow, Satara ------------------------*/}

      {/* <div className="projectsContainer residentialWrap flat">
        <h2>Hira Bungalow, Satara</h2>
        <div className="projectsWrap">
          {SataraPics.map((SataraPic) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={SataraPic.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{SataraPic.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}

        {/*-------------------- Bungalow at Kota, Rajasthan ------------------------*/}

      <div className="projectsContainer residentialWrap flat">
        <h2>Bungalow at Kota, Rajasthan</h2>
        <div className="projectsWrap">
          {KotaPics.map((KotaPic) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={KotaPic.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{KotaPic.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

        {/*-------------------- Amazeum Park ------------------------*/}

      <div className="projectsContainer residentialWrap flat">
        <h2>Amazeum Park</h2>
        <div className="projectsWrap">
          {AmazeumParkPics.map((AmazeumParkPic) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={AmazeumParkPic.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{AmazeumParkPic.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

        {/*-------------------- Elegant-Dgains Homes ------------------------*/}
      
      <div className="projectsContainer residentialWrap flat">
        <h2> Elegant-Dgains Homes</h2>
        <div className="projectsWrap">
          {DgainsPics.map((DgainsPic) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={DgainsPic.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{DgainsPic.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
    </div>
  );
}
