// import React, { useEffect } from "react";
import React from "react";
import "./ServicesDesc.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export default function ServicesDesc() {
  // useEffect(() => {
    
  //   //------------------------ Image Animation-------------------//
  //   gsap.from(".S1 img", {
  //     duration: 0.5,
  //     x: "-100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S1 img",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S2 img", {
  //     duration: 0.5,
  //     x: "100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S2 img",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S3 img", {
  //     duration: 0.5,
  //     x: "-100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S3 img",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S4 img", {
  //     duration: 0.5,
  //     x: "100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S4 img",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S5 img", {
  //     duration: 0.5,
  //     x: "-100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S5 img",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S6 img", {
  //     duration: 0.5,
  //     x: "100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S6 img",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S7 img", {
  //     duration: 0.5,
  //     x: "-100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S7 img",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S8 img", {
  //     duration: 0.5,
  //     x: "100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S8 img",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S9 img", {
  //     duration: 0.5,
  //     x: "-100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S9 img",
  //       start: "top 90%",
  //     },
  //   });
    
  //   //------------------------ Text H2 Animation-------------------//
  //   gsap.from(".S1 h2", {
  //     duration: 1,
  //     x: "200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S1 h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S2 h2", {
  //     duration: 1,
  //     x: "-200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S2 h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S3 h2", {
  //     duration: 1,
  //     x: "200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S3 h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S4 h2", {
  //     duration: 1,
  //     x: "-200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S4 h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S5 h2", {
  //     duration: 1,
  //     x: "200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S5 h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S6 h2", {
  //     duration: 1,
  //     x: "-200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S6 h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S7 h2", {
  //     duration: 1,
  //     x: "200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S7 h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S8 h2", {
  //     duration: 1,
  //     x: "-200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S8 h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".S9 h2", {
  //     duration: 1,
  //     x: "200",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S9 h2",
  //       start: "top 90%",
  //     },
  //   });

  //   //------------------------ Text Para Animation-------------------//
  //   gsap.from(".S1 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S1 p",
  //       start: "top 98%",
  //     },
  //   });
  //   gsap.from(".S2 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S2 p",
  //       start: "top 98%",
  //     },
  //   });
  //   gsap.from(".S3 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S3 p",
  //       start: "top 98%",
  //     },
  //   });
  //   gsap.from(".S4 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S4 p",
  //       start: "top 98%",
  //     },
  //   });
  //   gsap.from(".S5 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S5 p",
  //       start: "top 98%",
  //     },
  //   });
  //   gsap.from(".S6 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S6 p",
  //       start: "top 98%",
  //     },
  //   });
  //   gsap.from(".S7 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S7 p",
  //       start: "top 98%",
  //     },
  //   });
  //   gsap.from(".S8 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S8 p",
  //       start: "top 98%",
  //     },
  //   });
  //   gsap.from(".S9 p", {
  //     duration: 1,
  //     y: "50",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".S9 p",
  //       start: "top 98%",
  //     },
  //   });
  // });

  return (
    <>
      <div className="servicesDescWrap">
        <div className="servicesDescContainer S1" id='S1'>
          <div className="servicesDescImg ">
            <img src={require("./ServicesImages/S1.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>RCC Structures</h2>
            <p>
              We design safe, efficient and cost effective Residential, Commercial, Institutional and Industrial buildings in RCC framed structures
              considering latest codes.
            </p>
          </div>
        </div>

        <div className="servicesDescContainer S2" id='S2'>
          <div className="servicesDescImg">
            <img src={require("./ServicesImages/S2.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>MS Structures</h2>
            <p>
              We design commercial and industrial buildings in MS framed structures considering latest codes. In addition to conventional design, we
              also design pre-engineering type structures.
            </p>
          </div>
        </div>

        <div className="servicesDescContainer S3" id='S3'>
          <div className="servicesDescImg">
            <img src={require("./ServicesImages/S3.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>Hoardings</h2>
            <p>We design safe economical and efficient billboards of varying sizes in MS frame structures considering latest wind codes.</p>
          </div>
        </div>

        <div className="servicesDescContainer S4" id='S4'>
          <div className="servicesDescImg">
            <img src={require("./ServicesImages/S4.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>Post Tensioning</h2>
            <p>
              We design and execute large span structures by implementing post-tensioning technique. This system is economical and widely used in
              commercial industrial and institutional buildings.
            </p>
          </div>
        </div>

        <div className="servicesDescContainer S5" id='S5'>
          <div className="servicesDescImg">
            <img src={require("./ServicesImages/S5.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>Peer review</h2>
            <p>
              We undertake a peer review of old and new building designs and suggest the possible alternatives. This ensures safety of the buildings
              and cost-effective as well.
            </p>
          </div>
        </div>

        <div className="servicesDescContainer S6" id='S6'>
          <div className="servicesDescImg">
            <img src={require("./ServicesImages/S6.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>Audit and Retrofitting</h2>
            <p>
              As buildings become older, the repair works are to be carried out mandatorily for its safety. We carry out audit of old buildings and
              provide the retro-fitting design and details for all types of buildings.
            </p>
          </div>
        </div>

        <div className="servicesDescContainer S7" id='S7'>
          <div className="servicesDescImg">
            <img src={require("./ServicesImages/S7.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>Rock Anchoring</h2>
            <p>
              The uplift pressure of water on basement slab become serious issue in coastal areas like Mumbai, Madras etc. To resist the water
              pressure, it is required to design the base slab and beams for such water pressure and this become uneconomical. To reduce the cost, we
              provide design and services of rock anchoring which is time saving as well.
            </p>
          </div>
        </div>

        <div className="servicesDescContainer S8" id='S8'>
          <div className="servicesDescImg">
            <img src={require("./ServicesImages/S8.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>MEP</h2>
            <p>
              We undertake MEP design and execution work for residential commercial industrial and institutional buildings does providing most of the
              services under one roof.
            </p>
          </div>
        </div>

        <div className="servicesDescContainer S9" id='S9'>
          <div className="servicesDescImg">
            <img src={require("./ServicesImages/S9.png")} alt="Services" />
          </div>
          <div className="servicesDescContent">
            <h2>Shuttering Design</h2>
            <p>
              Shuttering is designed for safe transfer of dead and working loads with atmost care to avoid any untoward incident at site. We design
              wooden and MS shuttering to overcome such issues.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
