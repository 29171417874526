import React from "react";
import "./Sector.css";

const PublicLogos = [
  { url: require("./ClientsImages/PrivateSector/21.PNG"), name: "Aditya Birla Group" },
  { url: require("./ClientsImages/PrivateSector/22.PNG"), name: "Tata Tele Services Maharashtra Ltd." },
  { url: require("./ClientsImages/PrivateSector/23.PNG"), name: "Adani Electricity Mumbai Ltd." },
  { url: require("./ClientsImages/PrivateSector/24.PNG"), name: "Maersk  Integrated Container Logistics & Supply Chain Services" },
  { url: require("./ClientsImages/PrivateSector/25.PNG"), name: "APM Terminals India Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/26.PNG"), name: "Afcons Infrastructure Ltd." },
  { url: require("./ClientsImages/PrivateSector/27.PNG"), name: "Hilton Mumbai International Airport" },
  { url: require("./ClientsImages/PrivateSector/28.PNG"), name: "Burger King Fast Food Restaurant Company" },
  { url: require("./ClientsImages/PrivateSector/29.PNG"), name: "Lite Bite Foods Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/30.PNG"), name: "Middle East Tourism Expo Erbil - Hewa, Iraq" },
  { url: require("./ClientsImages/PrivateSector/31.PNG"), name: "Tribhovandas Bhimji Zaveri Ltd." },
  { url: require("./ClientsImages/PrivateSector/32.PNG"), name: "Adkure Pharmaceuticals Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/33.PNG"), name: "Infosoft Digital Design & Services Pvt. Ltd." },
  { url: require("./ClientsImages/PrivateSector/34.PNG"), name: "Laqshy Media Group Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/35.PNG"), name: "Times OOH" },
  { url: require("./ClientsImages/PrivateSector/36.PNG"), name: "RoshanSpace Brandcom Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/37.PNG"), name: "Signpost India Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/38.PNG"), name: "Wallop Advertising Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/39.PNG"), name: "CtrlS Data Center Ltd." },
  { url: require("./ClientsImages/PrivateSector/40.PNG"), name: "Intex Technologies" },
  { url: require("./ClientsImages/PrivateSector/41.PNG"), name: "UHP Technologies" },
  { url: require("./ClientsImages/PrivateSector/42.PNG"), name: "Super Max" },
  { url: require("./ClientsImages/PrivateSector/43.PNG"), name: "Hygienic Research Institute" },
  { url: require("./ClientsImages/PrivateSector/44.PNG"), name: "DGAINS Bhoomi Nirman Ltd." },
  { url: require("./ClientsImages/PrivateSector/45.PNG"), name: "Rajarajesware Builddcon Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/46.PNG"), name: "Parinee Building Proportions" },
  { url: require("./ClientsImages/PrivateSector/47.PNG"), name: "Je & Vee Infrastructures Pvt Ltd." },
  { url: require("./ClientsImages/PrivateSector/48.PNG"), name: "Oberoi Reality Ltd." },
  { url: require("./ClientsImages/PrivateSector/49.PNG"), name: "Abhay Builders & Developers" },
  { url: require("./ClientsImages/PrivateSector/50.PNG"), name: "Min Toy Pvt Ltd." },
];

export default function PrivateSector() {
  return (
    <div className="sectorWrap">
      <div className="sectorHeading">
        <h2 className="sectorHeadingPrivate">Our Clients</h2>
        <div className="sectorType sectorTypePrivate">
          <h3>Private Sector</h3>
        </div>
      </div>
      <div className="logoContainer">
        {PublicLogos.map((PublicLogo) => {
          return (
            <div className="logoBox">
              <div className="logoWrap">
                <img src={PublicLogo.url} alt="Logo" />
              </div>
              <div className="logoOverlay">
                <div className="logoText">{PublicLogo.name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
