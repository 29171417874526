import React, { useEffect } from "react";
import emailjs from "emailjs-com";
import "./Form.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Form() {
  useEffect(() => {
    gsap.from(".formContent", {
      duration: 1,
      y: "100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".formContent",
        start: "top 90%",
      },
    });
  });

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm("website", "template_y961wvn", e.target, "user_ar2T2KGMHndfRTgcC9M09").then(
      (result) => {
        console.log(result.text);
        alert("Thank You, Message Received. We will get back to you soon");
      },
      (error) => {
        console.log(error.text);
        alert("Some error occured. Try again");
      }
    );
    e.target.reset();
  }

  return (
    <div>
      <div className="formContainer">
        <form onSubmit={sendEmail}>
          <div className="formWrap">
            <div className="formWrapFlex">
              <div className="formContent">
                <p>
                  Your Name<span>&nbsp;*</span>
                </p>
                <input type="text" className="formInput" placeholder="Name" name="name" required />
              </div>

              <div className="formContent">
                <p>
                  Your Email<span>&nbsp;*</span>
                </p>
                <input type="email" className="formInput" placeholder="ansari@aaandassociates.in" name="email" required />
              </div>
            </div>

            <div className="formWrapFlex">
              <div className="formContent">
                <p>
                  Subject<span>&nbsp;*</span>
                </p>
                <input type="text" className="formInput" placeholder="Structural Design" name="subject" required />
              </div>

              <div className="formContent">
                <p>
                  Your Phone Number<span>&nbsp;*</span>
                </p>
                <input type="text" className="formInput" placeholder="9892755199" name="number" required />
              </div>
            </div>

            <div className="formContent">
              <p>
                How can we help you?<span>&nbsp;*</span>
              </p>
              <textarea type="text" className="formInput messageInput" placeholder="Hi there, I would like to ..." name="message" required />
            </div>

            <div className="formContentbtn">
              <input type="submit" className="btn" value="Send Message" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
