import React from "react";
import "./Sector.css";

const PublicLogos = [
  { url: require("./ClientsImages/PublicSector/1.PNG"), name: "Navi Mumbai Municipal Corporation" },
  { url: require("./ClientsImages/PublicSector/2.PNG"), name: "Mumbai Metropolitan Region Development Authority" },
  { url: require("./ClientsImages/PublicSector/3.PNG"), name: "CSC e-Governance Services India Ltd." },
  { url: require("./ClientsImages/PublicSector/4.PNG"), name: "Municipal Corporation of Greater Mumbai" },
  { url: require("./ClientsImages/PublicSector/5.PNG"), name: "Indian Air Force" },
  { url: require("./ClientsImages/PublicSector/6.PNG"), name: "Mumbai Port Trust" },
  { url: require("./ClientsImages/PublicSector/7.PNG"), name: "Chhatrapati Shivaji International Airport Mumbai" },
  { url: require("./ClientsImages/PublicSector/8.PNG"), name: "GVK at Mumbai Airport" },
  { url: require("./ClientsImages/PublicSector/9.PNG"), name: "Konkan Railway Corporation Ltd." },
  { url: require("./ClientsImages/PublicSector/10.PNG"), name: "National Institute Of Research In Reproductive Health " },
  { url: require("./ClientsImages/PublicSector/11.PNG"), name: "Indian Institute of Technology Hyderabad" },
  { url: require("./ClientsImages/PublicSector/12.PNG"), name: "Raja Ramanna Centre for Advanced Technology" },
  { url: require("./ClientsImages/PublicSector/13.PNG"), name: "Abhyudaya Co-operative Bank Ltd." },
  { url: require("./ClientsImages/PublicSector/14.PNG"), name: "Bait un Nasr Bank Ltd." },
  { url: require("./ClientsImages/PublicSector/15.PNG"), name: "Zakat Foundation of India" },
  { url: require("./ClientsImages/PublicSector/16.PNG"), name: "EXPO Gas Containers Ltd." },
  { url: require("./ClientsImages/PublicSector/17.PNG"), name: "Fazlani L'Académie Globale" },
  { url: require("./ClientsImages/PublicSector/18.PNG"), name: "Tasgaonkar Group of Institute, Karjat" },
  { url: require("./ClientsImages/PublicSector/19.PNG"), name: "Don Bosco High School, Borivali" },
  { url: require("./ClientsImages/PublicSector/20.PNG"), name: "Villa Theresa High School, Mumbai" },
];

export default function PublicSector() {
  return (
    <div className="sectorWrap">
      <div className="sectorHeading">
        <h2>Our Clients</h2>
        <div className="sectorType">
          <h3>Public Sector</h3>
        </div>
      </div>
      <div className="logoContainer">
        {PublicLogos.map((PublicLogo) => {
          return (
            <div className="logoBox">
              <div className="logoWrap">
                <img src={PublicLogo.url} alt="Logo" />
              </div>
              <div className="logoOverlay">
                <div className="logoText">{PublicLogo.name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
