import React from "react";
import "./AllProjects.css";

const ResidentialTowers1 = [
  { url: require("./Projects-Images/1-Residential-Towers/01 Blue Heaven Infra - AA & Associates.jpg"), name: "Blue Heaven Infra, Bhayandar" },
  { url: require("./Projects-Images/1-Residential-Towers/02 Akruti Empire at Mira Road - AA & Associates.jpg"), name: "Akruti Empire, Mira Road" },
  { url: require("./Projects-Images/1-Residential-Towers/03 Galaxy Tower at Raipur - AA & Associates.jpg"), name: "Galaxy Tower, Raipur" },
];
const ResidentialTowers2 = [
  {
    url: require("./Projects-Images/1-Residential-Towers/04 Mirabella, Noida - AA & Associates.jpg"),
    name: " Mirabella, Noida",
  },
  {
    url: require("./Projects-Images/1-Residential-Towers/05 Panchvati Heights at Mira Road - AA & Associates.jpg"),
    name: "Panchvati Heights, Mumbai",
  },
  { url: require("./Projects-Images/1-Residential-Towers/06 Sai Aakruti Empire - AA & Associates.jpg"), name: "Sai Aakruti, Mumbai" },
 
  { url: require("./Projects-Images/1-Residential-Towers/07 DBR Classics at Badlapur - AA & Associates.jpg"), name: "DBR Classics, Badlapur" },
  {
    url: require("./Projects-Images/1-Residential-Towers/08 Chaitenya Heights at Mira Road - AA & Associates.jpg"),
    name: "Chaitenya Heights, Mumbai",
  },
  { url: require("./Projects-Images/1-Residential-Towers/09 Surya Darshan Apartments - AA & Associates.jpg"), name: "Surya Darshan, Mumbai" },
  { url: require("./Projects-Images/1-Residential-Towers/10 Sai Prasad Apartments - AA & Associates.jpg"), name: "Sai Prasad Apartments, Mumbai" },
  { url: require("./Projects-Images/1-Residential-Towers/11 Mansuri Mansion - AA & Associates.jpg"), name: "Mansuri Mansion, Mumbai" },
  {
    url: require("./Projects-Images/1-Residential-Towers/12 Sai Puja Enclave at Mira Road - AA & Associates.jpg"),
    name: "Sai Puja Enclave, Mira Road",
  },
  { url: require("./Projects-Images/1-Residential-Towers/13 Sai Samarpan at Bhayandar - AA & Associates.jpg"), name: "Sai Samarpan, Bhayandar" },
  { url: require("./Projects-Images/1-Residential-Towers/14 Rahi Arcade at Mira Road - AA & Associates.jpg"), name: "Rahi Arcade, Mira Road" },
  {
    url: require("./Projects-Images/1-Residential-Towers/15 Sheetal Complex at Mira Road - AA & Associates.jpg"),
    name: "Sheetal Complex, Mira Road",
  },
  {
    url: require("./Projects-Images/1-Residential-Towers/16 Kasturi Vandana Complex at Bhayandar - AA & Associates.jpg"),
    name: "Kasturi Vandana, Bhayandar",
  },
  { url: require("./Projects-Images/1-Residential-Towers/17 Navkar Apartments - AA & Associates.jpg"), name: "Navkar Apartments, Mumbai" },
  {
    url: require("./Projects-Images/1-Residential-Towers/18 Aasiya Heights at Mira Road - AA & Associates.jpg"),
    name: "Aasiya Heights, Mumbai",
  },
];
const MahagunPics = [
  {
    url: require("./Projects-Images/1-Residential-Towers/Mahagun Mezzaria at Noida/Mahagun Mezzaria at Noida 1 - AA & Associates.jpg"),
    name: "Day View",
  },
  {
    url: require("./Projects-Images/1-Residential-Towers/Mahagun Mezzaria at Noida/Mahagun Mezzaria at Noida 2 - AA & Associates.jpg"),
    name: "Night View",
  },
  {
    url: require("./Projects-Images/1-Residential-Towers/Mahagun Mezzaria at Noida/Mahagun Mezzaria at Noida 3 - AA & Associates.jpg"),
    name: "Pool View",
  },
];
const BhoomiyugPics = [
  {
    url: require("./Projects-Images/1-Residential-Towers/Bhoomiyug-Apartments-at-Vasai/Bhoomiyug 0 - AA & Associates.jpg"),
    name: "Layout",
  },
  {
    url: require("./Projects-Images/1-Residential-Towers/Bhoomiyug-Apartments-at-Vasai/Bhoomiyug 1 - AA & Associates.jpg"),
    name: "View 1",
  },
  {
    url: require("./Projects-Images/1-Residential-Towers/Bhoomiyug-Apartments-at-Vasai/Bhoomiyug 2 - AA & Associates.jpg"),
    name: "View 2",
  },
];

export default function Residential_Towers() {
  return (
    <div id="RT">
      <div className="projectsContainer">
        <h2>Residential Projects</h2>
        <div className="projectsWrap">
          {ResidentialTowers1.map((ResidentialTower1) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={ResidentialTower1.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{ResidentialTower1.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="projectsContainer residentialWrap">
        <h2>Mahagun Mezzaria Towers, Noida</h2>
        <div className="projectsWrap">
          {MahagunPics.map((MahagunPic) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={MahagunPic.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{MahagunPic.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="projectsContainer residentialTowerContinue">
        <div className="projectsWrap">
          {ResidentialTowers2.map((ResidentialTower2) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={ResidentialTower2.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{ResidentialTower2.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="projectsContainer residentialWrap flat">
        <h2>Bhoomiyug Apartments</h2>
        <div className="projectsWrap">
          {BhoomiyugPics.map((BhoomiyugPic) => {
            return (
              <div className="projects">
                <div className="projectsPhotos">
                  <img src={BhoomiyugPic.url} alt="Projects" />
                  <div className="projectsNames">
                    <div className="projectsNamesContent">
                      <h4 className="projectsText">{BhoomiyugPic.name}</h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
