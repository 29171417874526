import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./SlideMobile.css";

const photosMobile = [
  {
    name: "Mirabella, Noida",
    description: "Consultancy of RCC Structures",
    url: require("./Images/M1 Mirabella, Noida - AA & Associates.jpg"),
  },
  {
    name: "Majidi Mall, Iraq",
    description: "Consultancy of MS Structures",
    url: require("./Images/M2 Majidi Mall at Sulemaniya, Iraq - AA & Associates.jpg"),
  },
  { 
    name: "Bhoomiyug Appartments, Kaman, Vasai ",
    description: "Consultancy of Clusters",
    url: require("./Images/M3 Bhoomiyug Appartments, Kaman, Vasai - AA & Associates.jpg"),
  },
  // {
  //   name: "Heera Bungalow, Satara",
  //   description: "Post-Tensioning Consultancy",
  //   url: require("./Images/M4 Heera Bungalow, Satara - AA & Associates.jpg"),
  // },
  {
    name: "Club House, Naupada, Orissa",
    description: "Public Buildings",
    url: require("./Images/M5 Club House, Naupada, Orissa - AA & Associates.jpg"),
  },
  {
    name: "Times OOH Hoarding at Chhatrapati Shivaji Airport, Mumbai",
    // name: "Times OOH Hoarding",
    description: "Consultancy of Hoardings",
    url: require("./Images/M6 Times OOH Hoarding at Chhatrapati Shivaji Airport, Mumbai - AA & Associates.jpg"),
  },
  {
    name: "RRBC Mall, Hindupur",
    // description: "Commmercial Buildings",
    description: "Post-Tensioning Consultancy",
    url:require('./Images/M7 RRBC Mall, Hindupur - AA & Associates.jpg')
  },
];

class SlideMobile extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      className: "slidesMobile",
    };

    return (
      <div className="slideMobile">
        <Slider {...settings}>
          {photosMobile.map((photo) => {
            return (
              <div>
                <img src={photo.url} alt="Images" />
                <div className="slideDescriptionMobile">
                  <h3>{photo.description}</h3>
                  <h4>{photo.name}</h4>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default SlideMobile;
