import React, { useEffect } from "react";
import "./Vision.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Vision() {
  useEffect(() => {
    gsap.from(".visionWrap", {
      duration: 1,
      x: "-100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".visionWrap",
        start: "top 90%",
      },
    });
    gsap.from(".missionWrap", {
      duration: 1,
      x: "100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".missionWrap",
        start: "top 90%",
      },
    });
  });

  return (
    <div className="visionMission">
      <div className="visionMissionBackground">
        <div className="visionWrap">
          <div className="visionLogo">
            <i class="far fa-eye"></i>
          </div>
          <div className="visionHeading">
            <h3>Vision</h3>
          </div>
          <div className="visionDescription">
            <p>To deliver innovative, optimum and secured structural design solutions leading to complete clients satisfaction.</p>
          </div>
        </div>
        <div className="missionWrap">
          <div className="missionLogo">
            <i class="fas fa-crosshairs"></i>
          </div>
          <div className="missionHeading">
            <h3>Mission</h3>
          </div>
          <div className="missionDescription">
            <ul>
              <li>
                <span>To provide reliable, efficient, safe and sturdy structural designs.</span>
              </li>
              <li>
                <span>To give cost – effective, customized structural solutions that accomplish clients aspiration.</span>
              </li>
              <li>
                <span>To implement highest standards and creative designs using cutting - edge technology with ethical practice.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
