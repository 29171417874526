import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <div className='footerFlex'>
      <div className="excellence">
        <img src={require("./Excellence - AA & Associates.png")} alt="excellence" />
      </div>
      <div className="footerWrap">

        {/* ------------ Address -------------- */}

        <div className="homeContact">
          <i class="fas fa-map-marker-alt fa-2x"></i>
          <p>105, Bldg No. 3, Sagar City, VP Road, Andheri (W), Mumbai - 400058 </p>
        </div>

        {/* ------------ Phone Number -------------- */}

        <div className="homeContact">
          <i class="fas fa-phone fa-2x"></i>
            <div>
              <p>Cell : &nbsp;+91 9892755199</p>
              <p>Off &nbsp;: &nbsp;+91 9820138517</p>
            </div>
        </div>

        {/* ------------ Email -------------- */}

        <div className="homeContact">
          <i class="fas fa-envelope"></i>
          <p>ansari@aaandassociates.in</p>
        </div>

        {/* ------------ Icons Footer -------------- */}

        <div class="iconsFooter">
          <a class="btnFooter" href="https://www.facebook.com/mohdahteshamuddin.ansari.16" target='_blank' rel="noopener noreferrer">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a class="btnFooter" href="https://www.linkedin.com/in/aa-associates-8120551ba/"   target='_blank' rel="noopener noreferrer">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a class="btnFooter" href="https://twitter.com/AaAssociates" target='_blank' rel="noopener noreferrer">
            <i class="fab fa-twitter"></i>
          </a>
          <a class="btnFooter" href="https://www.instagram.com/aa.associates/" target='_blank' rel="noopener noreferrer">
            <i class="fab fa-instagram"></i>
          </a>
        </div>
        
        <div className="FooterBottomLine"></div>
        
        {/* ------------ Copyright -------------- */}
        
        <div className="copyright">
          <p>
            Copyright <i class="far fa-copyright"></i> 2003-2021 | AA & Associates | All Rights Reserved
          </p>
        </div>
      </div>
    </div>
    
        {/* ------------ Developer -------------- */}

    <div className="designed">
          <p>Designed & Developed by - <span>Umair Ansari</span></p>
          <p><span>+91 9036408076</span></p>
    </div>
  </div>
  );
}
