import React from "react";
import AboutBackground from "./AboutUsComponents/AboutBackground";
import WhoWeAre from "./AboutUsComponents/WhoWeAre";
import Vision from "./AboutUsComponents/Vision";
import CEO from "./AboutUsComponents/CEO";

export default function About() {
  return (
    <>
      <AboutBackground />
      <WhoWeAre />
      <Vision />
      <CEO />
    </>
  );
}
