import React, { useState } from "react";
import { NavHashLink } from 'react-router-hash-link';
import './Dropdown.css'

const MenuItems = [
    {
        title: 'RCC Structures',
        path: '/services#S1',
        cName: 'dropdown-list',
        // href: '/services#S1'
    },
    {
        title: 'MS Structures',
        path: '/services#S2',
        cName: 'dropdown-list',
        // href: '/services#S2'
    },
    {
        title: 'Hoardings',
        path: '/services#S3',
        cName: 'dropdown-list',
        // href: '/services#S3'
    },
    {
        title: 'Post Tensioning',
        path: '/services#S4',
        cName: 'dropdown-list',
        // href: '/services#S4'
    },
    {
        title: 'Peer Review',
        path: '/services#S5',
        cName: 'dropdown-list',
        // href: '/services#S5'
    },
    {
        title: 'Audit & Retro-Fitting',
        path: '/services#S6',
        cName: 'dropdown-list',
        // href: '/services#S6'
    },
    {
        title: 'Rock Anchoring',
        path: '/services#S7',
        cName: 'dropdown-list',
        // href: '/services#S7'
    },
    {
        title: 'MEP',
        path: '/services#S8',
        cName: 'dropdown-list',
        // href: '/services#S8'
    },
    {
        title: 'Shuttering Design',
        path: '/services#S9',
        cName: 'dropdown-list',
        // href: '/services#S9'
    },
]

export default function Dropdown() {
const [click] = useState(false)


    return (
        <>
            <ul className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
                {MenuItems.map((item) => {
                    return(
                        <li>
                            <NavHashLink className={item.cName} to={item.path} >
                                {item.title}
                            </NavHashLink>
                        </li>
                    )
                })}
            </ul>
        </>
    )
} 



