import React, { useState } from "react";
import { Link } from "react-scroll";
// import "./AllProjects.css";
import "./ProjectNav.css";

export default function ProjectNav() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <div className="projectNav">
      <div className="project-icon" onClick={handleClick}>
        Categories
      </div>


      <ul className={click ? "project-menu active" : "project-menu"}>

      {/*------------------ Residential-Buildings -----------------*/}

        <li className="project-item">
          <Link
            to="RT"
            className="project-links"
            activeClass="project-nav-active"
            spy={true}
            onClick={closeMobileMenu}
            smooth={true}
            duration={100}
            offset={-70}
          >
            Residential-Projects
          </Link>
        </li>

      {/*------------------ Commercial-Buildings -----------------*/}

        <li className="project-item">
          <Link
            to="CB"
            className="project-links"
            activeClass="project-nav-active"
            spy={true}
            onClick={closeMobileMenu}
            smooth={true}
            duration={100}
            offset={-70}
          >
            Commercial-Projects
          </Link>
        </li>

      {/*------------------ Public-Buildings -----------------*/}

        <li className="project-item">
          <Link
            to="PB"
            className="project-links"
            activeClass="project-nav-active"
            spy={true}
            onClick={closeMobileMenu}
            smooth={true}
            duration={100}
            offset={-70}
          >
            Public-Buildings
          </Link>
        </li>

      {/*------------------ Villas -----------------*/}

        <li className="project-item">
                <Link
                  to="RV"
                  className="project-links"
                  activeClass="project-nav-active"
                  spy={true}
                  onClick={closeMobileMenu}
                  smooth={true}
                  duration={100}
                  offset={-70}
                >
                  Villas
                </Link>
              </li>

      {/*------------------ Post-Tensioning -----------------*/}

        <li className="project-item">
                <Link
                  to="PT"
                  className="project-links"
                  activeClass="project-nav-active"
                  spy={true}
                  onClick={closeMobileMenu}
                  smooth={true}
                  duration={100}
                  offset={-70}
                >
                  Post-Tensioning
                </Link>
              </li>

      {/*------------------ MS-Structures -----------------*/}

        <li className="project-item">
          <Link
            to="MS"
            className="project-links"
            activeClass="project-nav-active"
            spy={true}
            onClick={closeMobileMenu}
            smooth={true}
            duration={100}
            offset={-70}
          >
            MS-Structures
          </Link>
        </li>

      {/*------------------ Hoardings -----------------*/}

        <li className="project-item">
          <Link
            to="HO"
            className="project-links"
            activeClass="project-nav-active"
            spy={true}
            onClick={closeMobileMenu}
            smooth={true}
            duration={100}
            offset={-70}
          >
            Hoardings
          </Link>
        </li>

      {/*------------------ Audit & Retro-Fitting -----------------*/}

        <li className="project-item">
          <Link
            to="AR"
            className="project-links"
            activeClass="project-nav-active"
            spy={true}
            onClick={closeMobileMenu}
            smooth={true}
            duration={100}
            offset={-70}
          >
            Audit & Retro-Fitting
          </Link>
        </li>

      {/*------------------ Rock-Anchoring -----------------*/}

        <li className="project-item">
          <Link
            to="RA"
            className="project-links"
            activeClass="project-nav-active"
            spy={true}
            onClick={closeMobileMenu}
            smooth={true}
            duration={100}
            offset={-70}
          >
            Rock-Anchoring
          </Link>
        </li>
      
      {/*------------------ MEP -----------------*/}

        <li className="project-item">
          <Link
            to="MEP"
            className="project-links"
            activeClass="project-nav-active"
            spy={true}
            onClick={closeMobileMenu}
            smooth={true}
            duration={100}
            offset={-70}
          >
            MEP
          </Link>
        </li>

      </ul>
    </div>
  );
}
