import React from "react";
import ClientsBackground from "./ClientsComponents/ClientsBackground";
import PublicSector from "./ClientsComponents/PublicSector";
import PrivateSector from "./ClientsComponents/PrivateSector";

export default function Client() {
  return (
    <>
      <ClientsBackground />
      <PublicSector />
      <PrivateSector />
    </>
  );
}
