import React from "react";
import "./FormHeading.css";

export default function FormHeading() {
  return (
    <div className="formHeading">
      <div className="topBorder"></div>
      <h4>Leave a Message</h4>
      <h2>
        Tell us about <span>yourself</span>
      </h2>
      <p>Whether you have questions or you would just like to say hello, drop a message.</p>
    </div>
  );
}
