import React from "react";
import "./AllProjects.css";

const PublicPics = [
  {
    url: require("./Projects-Images/3- Public-Buildings/01 Aditya Birla Public School, Orissa - AA & Associates.jpg"),
    name: "Aditya Birla Public School, Orissa",
  },
  {
    url: require("./Projects-Images/3- Public-Buildings/02 Club House, Mahan, MP - AA & Associates.jpg"),
    name: "Club House, Mahan, MP",
  },
  {
    url: require("./Projects-Images/3- Public-Buildings/03-Clubhouse at Oshapada, Orissa - AA & Associates.jpg"),
    name: "Clubhouse at Oshapada, Orissa",
  },
  {
    url: require("./Projects-Images/3- Public-Buildings/04-Clubhouse at Naupada, Orissa - AA & Associates.jpg"),
    name: "Clubhouse at Naupada, Orissa",
  },
  {
    url: require("./Projects-Images/3- Public-Buildings/05-Court Vennigton Hotel, Raipur - AA & Associates.jpg"),
    name: "Court Vennigton Hotel, Raipur",
  },
  {
    url: require("./Projects-Images/3- Public-Buildings/06 Ashrafiya Masjid, Matunga - AA & Associates.jpg"),
    name: "Ashrafiya Masjid, Matunga",
  },
  {
    url: require("./Projects-Images/3- Public-Buildings/07 Ayub Raza Jama Masjid, Jharkhand - AA & Associates.jpg"),
    name: "Ayub Raza Jama Masjid, Jharkhand",
  },
  {
    url: require("./Projects-Images/3- Public-Buildings/08 Badshahi Masjid, Manche - AA & Associates.jpg"),
    name: "Badshahi Masjid, Manche",
  },
  {
    url: require("./Projects-Images/3- Public-Buildings/09-Sunni Jama Masjid, Dharavi - AA & Associates.jpg"),
    name: "Sunni Jama Masjid, Dharavi",
  },
];

export default function PublicBuildings() {
  return (
    <div className="projectsContainer flat" id="PB">
      <h2>Public Buildings</h2>
      <div className="projectsWrap">
        {PublicPics.map((PublicPic) => {
          return (
            <div className="projects">
              <div className="projectsPhotos">
                <img src={PublicPic.url} alt="Projects" />
                <div className="projectsNames">
                  <div className="projectsNamesContent">
                    <h4 className="projectsText">{PublicPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
