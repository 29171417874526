import React from "react";
import "./AllProjects.css";

const PTPics = [
  {
    url: require("./Projects-Images/5-PT/01-Hira Bungalow, Satara - AA & Associates.jpg"),
    name: "Hira Bungalow, Satara",
  },
  {
    url: require("./Projects-Images/5-PT/02-Alliance Corp, Vasai East - AA & Associates.jpg"),
    name: "Alliance Corp, Vasai East",
  },
  {
    url: require("./Projects-Images/5-PT/3-Court Vennigton Hotel, Raipur - AA & Associates.jpg"),
    name: "Court Vennigton Hotel, Raipur",
  },
  {
    url: require("./Projects-Images/5-PT/04-Palmline Plastic Factory, Vasai East - AA & Associates.jpg"),
    name: "Palmline Plastic Factory, Vasai East",
  },
  {
    url: require("./Projects-Images/5-PT/05-Carmelite school, Vasai West - AA & Associates.jpg"),
    name: "Carmelite school, Vasai West",
  },
  {
    url: require("./Projects-Images/5-PT/06-Clubhouse at Naupada, Orissa - AA & Associates.jpg"),
    name: "Clubhouse at Naupada, Orissa",
  },
  {
    url: require("./Projects-Images/5-PT/07-Nidhi Industries, Vasai East - AA & Associates.jpg"),
    name: "Nidhi Industries, Vasai East",
  },
  {
    url: require("./Projects-Images/5-PT/08-Adkure Pharmaceutical Factory, Wada - AA & Associates.jpg"),
    name: "Adkure Pharmaceutical Factory, Wada",
  },
  {
    url: require("./Projects-Images/5-PT/09-Bhaati Tower, Malad East - AA & Associates.jpg"),
    name: "Bhaati Tower, Malad East",
  },
  {
    url: require("./Projects-Images/5-PT/10-Sunil Arcade IT Park at Mira Road - AA & Associates.jpg"),
    name: "Sunil Arcade IT Park, Mira Road",
  },
  {
    url: require("./Projects-Images/5-PT/11 Aditya Birla Public School, Orissa - AA & Associates.jpg"),
    name: "Aditya Birla Public School, Orissa",
  },
  {
    url: require("./Projects-Images/5-PT/12-Sunni Jama Masjid, Dharavi - AA & Associates.jpg"),
    name: "Sunni Jama Masjid, Dharavi",
  },
];

export default function PT() {
  return (
    <div className="projectsContainer flat" id="PT">
      <h2>Post Tensioning Projects</h2>
      <div className="projectsWrap">
        {PTPics.map((PTPic) => {
          return (
            <div className="projects">
              <div className="projectsPhotos">
                <img src={PTPic.url} alt="Projects" />
                <div className="projectsNames">
                  <div className="projectsNamesContent">
                    <h4 className="projectsText">{PTPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
