import React from "react";
import "./AboutBackground.css";
import "../../Wave/Wave.css";

export default function AboutBackground() {
  return (
    <div className="backgroundAboutWrap">
      <div className="backgroundAbout">
        <div className="backgroundAboutContainer"></div>
      </div>
      <div className="backgroundAboutContent">
        <h1>About Us</h1>
        <p>
          You Dream It . . .<span>We Make It . . .</span>
        </p>
      </div>
      <div className="wave"></div>
    </div>
  );
}
