import React from "react";
import "./AllProjects.css";

const MSPics = [
  {
    url: require("./Projects-Images/6-MS Structures/01 RRCAT, Chandigadh - AA & Associates.jpg"),
    name: "RRCAT, Chandigadh",
  },
  {
    url: require("./Projects-Images/6-MS Structures/02 Lite Bite Foods, Andheri - AA & Associates.jpg"),
    name: "Lite Bite Foods, Andheri",
  },
  {
    url: require("./Projects-Images/6-MS Structures/03 Majidi Mall at Sulemaniya, Iraq - AA & Associates.jpg"),
    name: "Majidi Mall at Sulemaniya, Iraq",
  },
];

export default function MS() {
  return (
    <div className="projectsContainer flat" id="MS">
      <h2>MS Structures</h2>
      <div className="projectsWrap">
        {MSPics.map((MSPic) => {
          return (
            <div className="projects">
              <div className="projectsPhotos">
                <img src={MSPic.url} alt="Projects" />
                <div className="projectsNames">
                  <div className="projectsNamesContent">
                    <h4 className="projectsText">{MSPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
