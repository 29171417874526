import React from "react";
import { NavHashLink } from 'react-router-hash-link';
import "./HomeServices.css";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

const icons = [
  {
    url: require("./Images/S1.png"),
    name: "RCC Structures",
    path: "/services#S1",
  },
  {
    url: require("./Images/S2.png"),
    name: "MS Structures",
    path: "/services#S2",
  },
  {
    url: require("./Images/S3.png"),
    name: "Hoardings",
    path: "/services#S3",
    },
  {
    url: require("./Images/S4.png"),
    name: "Post Tensioning",
    path: "/services#S4",
    },
  {
    url: require("./Images/S5.png"),
    name: "Peer Review",
    path: "/services#S5",
    },
  {
    url: require("./Images/S6.png"),
    name: "Audit & Retro-Fitting",
    path: "/services#S6",
    },
  {
    url: require("./Images/S7.png"),
    name: "Rock Anchoring",
    path: "/services#S7",
    },
  {
    url: require("./Images/S8.png"),
    name: "MEP",
    path: "/services#S8",
    },
];

export default function HomeServices() {
  // useEffect(() => {
  //   gsap.from(".ourServicesBox h2", {
  //     duration: 1,
  //     y: "100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".ourServicesBox h2",
  //       start: "top 90%",
  //     },
  //   });
  //   gsap.from(".ourServicesIcon", {
  //     duration: 1,
  //     y: "100",
  //     opacity: 0,
  //     ease: "ease-in",
  //     scrollTrigger: {
  //       trigger: ".ourServicesIcon",
  //       start: "top 90%",
  //     },
  //   });
  // });

  return (
    // <div className="ourServicesContainer">
    <div className="ourServices">
      <div className="ourServicesBox">
        <h2>Our Services</h2>
        <div className="ourServicesContainer">
          <div className="ourServicesWrap">
            {icons.map((icon) => {
              return (
                <div className="ourServicesIcon">
                  <NavHashLink to={icon.path}>
                    <img src={icon.url} alt="Icon" />
                    <h4 className="ourServicesText">{icon.name}</h4>
                  </NavHashLink>
                </div>
              );
            })}
          </div>
        </div>
        <div className="borderBottom"></div>
      </div>
    </div>
  );
}
