import React from "react";
import Navbar from "./Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Home/Home";
import AboutUs from "../AboutUs/AboutUs";
import Services from "../Services/Services";
import Projects from "../Projects/Projects";
import Clients from "../Clients/Clients";
import ContactUs from "../ContactUs/ContactUs";
import ScrollToTop from "./ScrollToTop";

function NavbarRoute() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/services" exact component={Services} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/clients" exact component={Clients} />
        <Route path="/contact-us" exact component={ContactUs} />
      </Switch>
    </Router>
  );
}

export default NavbarRoute;
