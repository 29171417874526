import React, { useEffect } from "react";
import "./CEO.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function CEO() {
  useEffect(() => {
    gsap.from(".ceoHeading", {
      duration: 1,
      y: "100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".ceoHeading",
        start: "top 90%",
      },
    });
    gsap.from(".ceoDescription", {
      duration: 1,
      y: "150",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".ceoDescription",
        start: "top 90%",
      },
    });
  });

  return (
    <div className="ceoWrap">
      <div className="ceoHeading">
        <h2>Director</h2>
      </div>
      <div className="ceoContent">
        <div className="ceoImg">
          {/* <img src={require("./My-pic.jpg")} /> */}
          <img src={require("./My-Pic.jpeg")} alt="CEO-Img" />
        </div>
        <div className="ceoDescription">
          <p>
            &emsp;&emsp;&emsp;<strong>M. A. Ansari, Founder and Director,</strong> AA and Associates, a structural engineer by profession, completed
            his graduation in civil engineering and Master’s degree with specialisation in structural engineering from an accredited and renowned
            university from India.
          </p>
          <p>
            &emsp;&emsp;&emsp;He worked as a structural engineer and gathered experience on variety of projects for over decade before starting his
            own firm AA and Associates.
          </p>
          <p>
            &emsp;&emsp;&emsp;His innovative mind and extraordinary design capability in solving complex issues of structural field has made him one
            of the prominent and successful structural engineer and designer.
          </p>
          <p>
            &emsp;&emsp;&emsp; He is instrumental in designing several hundreds of projects that were successfully implemented in different parts of
            India and abroad.
          </p>
        </div>
      </div>
    </div>
  );
}
