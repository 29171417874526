import React, { useEffect } from "react";
import "./Address.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Address() {
  useEffect(() => {
    gsap.from(".message", {
      duration: 1,
      x: "-80",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".message",
        start: "top 90%",
      },
    });
    gsap.from(".meet", {
      duration: 1,
      y: "100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".meet",
        start: "top 90%",
      },
    });
    gsap.from(".call", {
      duration: 1,
      x: "80",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".call",
        start: "top 90%",
      },
    });
  });

  return (
    <>
      <div className="addressWrap">
        <div className="addressContent message">
          <i class="fas fa-envelope"></i>
          <h4>Message Us</h4>
          <p>ansari@aaandassociates.in</p>
        </div>
        <div className="addressContent meet">
          <div className="meetIcon">
            <i class="fas fa-globe"></i>
            <i class="fas fa-map-marker-alt"></i>
          </div>
          <h4>Meet Us</h4>
          <p>105, Bldg No. 3, Sagar City, VP Road, Andheri (W), Mumbai - 400058</p>
        </div>
        <div className="addressContent call">
          <i class="fas fa-phone-volume"></i>
          <h4>Call Us</h4>
          <p>Cell : +91 9892755199 </p>
          <p>Off &nbsp;: +91 9820138517</p>
        </div>
      </div>
    </>
  );
}
