import React from "react";
import "./AllProjects.css";

const MEPPics = [
  {
    url: require("./Projects-Images/10-MEP/Updating Soon - AA & Associates.jpg"),
    name: "Updating Soon",
  },
  {
    url: require("./Projects-Images/10-MEP/Updating Soon - AA & Associates.jpg"),
    name: "Updating Soon",
  },
  {
    url: require("./Projects-Images/10-MEP/Updating Soon - AA & Associates.jpg"),
    name: "Updating Soon",
  },
  ];

export default function Villas() {
  return (
    <div className="projectsContainer flat" id="MEP">
      <h2>MEP</h2>
      <div className="projectsWrap">
        {MEPPics.map((MEPPic) => {
          return (
            <div className="projects">
              <div className="projectsPhotos">
                <img src={MEPPic.url} alt="Projects" />
                <div className="projectsNames">
                  <div className="projectsNamesContent">
                    <h4 className="projectsText">{MEPPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
