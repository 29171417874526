import React from "react";
import "./Contact.css";
import ContactBackground from "./ContactComponents/ContactBackground";
import Address from "./ContactComponents/Address";
import Map from "./ContactComponents/Map";
import Form from "./ContactComponents/Form";
import FormHeading from "./ContactComponents/FormHeading";

export default function Contact() {
  return (
    <>
      <ContactBackground />
      <Address />
      <FormHeading />
      <Form />
      <Map />
    </>
  );
}
