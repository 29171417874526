import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 1024) {
      setDropdown(false)
    } else {
      setDropdown(true);
    }
  }

  const onMouseLeave = () => {
    if (window.innerWidth < 1024) {
      setDropdown(false)
    } else {
      setDropdown(false);
    }
  }

  return (
    <>
      <nav className="navbar">
        <Link to="/" className="navbar-logo">
          <img src={require("./Logo.png")} alt="Logo" />
          <div className="navbarBorder"></div>
          <h4>Consulting Structural Engineers</h4>
        </Link>

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>

        {/* ------------------------------------Home ------------------------------------------*/}

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavLink exact to="/" className="nav-links" activeClassName="main-nav" onClick={closeMobileMenu}>
              Home
            </NavLink>
          </li>

          {/* ------------------------------------Home ------------------------------------------*/}

          <li className="nav-item">
            <NavLink to="/about-us" className="nav-links" activeClassName="main-nav" onClick={closeMobileMenu}>
              About Us
            </NavLink>
          </li>

          {/* ------------------------------------Services ------------------------------------------*/}

          <li className="nav-item" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <NavLink to="/services" className="nav-links" activeClassName="main-nav" onClick={closeMobileMenu}>
              Services <i className='fas fa-caret-down' />
            </NavLink>
            {dropdown && <Dropdown />}
          </li>

          {/* ------------------------------------Projects ------------------------------------------*/}

          <li className="nav-item">
            <NavLink to="/projects" className="nav-links" activeClassName="main-nav" onClick={closeMobileMenu}>
              Projects
            </NavLink>
          </li>

          {/* ------------------------------------Clients ------------------------------------------*/}

          <li className="nav-item">
            <NavLink to="/clients" className="nav-links" activeClassName="main-nav" onClick={closeMobileMenu}>
              Clients
            </NavLink>
          </li>

          {/* ------------------------------------Contact Us ------------------------------------------*/}

          <li className="nav-item">
            <NavLink to="/contact-us" className="nav-links" activeClassName="main-nav" onClick={closeMobileMenu}>
              Contact Us
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
