import React from "react";
import "./ClientsBackground.css";

export default function ClientsBackground() {
  return (
    <div className="backgroundClientsWrap">
      <div className="backgroundClients">
        <div className="backgroundClientsContainer"></div>
      </div>
      <div className="backgroundClientsContent">
        <h1>Clientele</h1>
        <p>We aim to create lasting relationship with our clients & offer a full line of services and solutions in order to provide the best application possible.</p>
      </div>
      <div className="wave"></div>
    </div>
  );
}
