import React from "react";
import "./AllProjects.css";

const AuditPics = [
  {
    url: require("./Projects-Images/8-Audit/81 Audits/01 Society of Green Acre, Malad - AA & Associates.jpg"),
    name: "Society of Green Acre, Malad",
  },
  {
    url: require("./Projects-Images/8-Audit/81 Audits/02 Sasoon Building, Fort - AA & Associates.jpg"),
    name: "Sasoon Building, Fort",
  },
  {
    url: require("./Projects-Images/8-Audit/81 Audits/03 Tarvotti Bhavan Welfare Society, Mello Road - AA & Associates.jpg"),
    name: "Tarvotti Bhavan Welfare Society, Mello Road",
  },
  {
    url: require("./Projects-Images/8-Audit/81 Audits/04 Afcons Corporate Office, Andheri - AA & Associates.jpg"),
    name: "Afcons Corporate Office, Andheri",
  },
  {
    url: require("./Projects-Images/8-Audit/81 Audits/05 Tejpal Industrial Estate Building, Andheri - AA & Associates.jpg"),
    name: "Tejpal Industrial Estate Building, Andheri",
  },
  {
    url: require("./Projects-Images/8-Audit/81 Audits/06 Borivali Prajakta, Borivali - AA & Associates.jpg"),
    name: "Borivali Prajakta, Borivali",
  },
  {
    url: require("./Projects-Images/8-Audit/81 Audits/07 Min Toy Factory, Silvassa - AA & Associates.jpg"),
    name: "Min Toy Factory, Silvassa",
  },
  {
    url: require("./Projects-Images/8-Audit/81 Audits/08 Kotak Bank, BKC - AA & Associates.jpg"),
    name: "Kotak Bank, BKC",
  },
  {
    url: require("./Projects-Images/8-Audit/81 Audits/09 Retrofitting & Repair of Phoenix Mill, Lower Parel - AA & Associates.jpg"),
    name: "Retrofitting & Repair of Phoenix Mill, Lower Parel",
  },
];
const LonavalaPics = [
  {
    url: require("./Projects-Images/8-Audit/82 Lonavala Bungalow/01 Open View - AA & Associates.jpg"),
    name: "Open View",
  },
  {
    url: require("./Projects-Images/8-Audit/82 Lonavala Bungalow/02 Side View - AA & Associates.jpg"),
    name: "Side View",
  },
  {
    url: require("./Projects-Images/8-Audit/82 Lonavala Bungalow/03 Road Face View - AA & Associates.jpg"),
    name: "Road Face View",
  },
];

export default function Villas() {
  return (
    <div id="AR">
      <div className="projectsContainer flat" >
      <h2>Audit & Retro-Fitting</h2>
      <div className="projectsWrap">
        {AuditPics.map((AuditPic) => {
          return (
            <div className="projects">
              <div className="projectsPhotos">
                <img src={AuditPic.url} alt="Projects" />
                <div className="projectsNames">
                  <div className="projectsNamesContent">
                    <h4 className="projectsText">{AuditPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>


    <div className="projectsContainer residentialWrap flat">
    <h2>Lonavala Bungalow</h2>
    <div className="projectsWrap">
      {LonavalaPics.map((LonavalaPic) => {
        return (
          <div className="projects">
            <div className="projectsPhotos">
              <img src={LonavalaPic.url} alt="Projects" />
              <div className="projectsNames">
                <div className="projectsNamesContent">
                  <h4 className="projectsText">{LonavalaPic.name}</h4>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  </div>
    </div>
  );
}
