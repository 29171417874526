import React from "react";
import "./AllProjects.css";

const AnchorPics = [
  {
    url: require("./Projects-Images/9-Rock-Anchoring/Updating Soon - AA & Associates.jpg"),
    name: "Updating Soon",
  },
    {
    url: require("./Projects-Images/9-Rock-Anchoring/Updating Soon - AA & Associates.jpg"),
    name: "Updating Soon",
  },
    {
    url: require("./Projects-Images/9-Rock-Anchoring/Updating Soon - AA & Associates.jpg"),
    name: "Updating Soon",
  },
  ];

export default function Villas() {
  return (
    <div className="projectsContainer flat" id="RA">
      <h2>Rock Anchoring</h2>
      <div className="projectsWrap">
        {AnchorPics.map((AnchorPic) => {
          return (
            <div className="projects">
              <div className="projectsPhotos">
                <img src={AnchorPic.url} alt="Projects" />
                <div className="projectsNames">
                  <div className="projectsNamesContent">
                    <h4 className="projectsText">{AnchorPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
