import React, { useEffect } from "react";
import "./HomeAbout.css";
import { NavLink } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function HomeAbout() {
  // let t1 = new TimelineLite({ delay: 0.3 });
  useEffect(() => {
    // t1.from(".bannerMain-rgt", 3, { y: 15, opacity: 0, ease: Power3.easeOut, delay: 0.2 }, "Start");
    // t1.staggerFrom(".text", 1, { y: 30, ease: Power3.easeOut, opacity: 0 }, 0.15, "Start")
    //   .from(".btn-primary", 1, { y: 20, opacity: 0, ease: Power3.easeOut, delay: 0.4 }, 0.35)
    //   .from(".paragraphAnimation", 1, { y: 10, opacity: 0, ease: Power3.easeOut, delay: 0.2 }, 0.35)
    //   .from("nav", 1, { y: -10, opacity: 0, ease: Power3.easeOut, delay: 0.2 }, 0.35, "Start");
    gsap.from(".homeAboutImg", {
      duration: 1,
      x: "100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".homeAboutImg",
        start: "top 90%",
        // markers: { startColor: "green", endColor: "red", fontSize: "12px" },
        // invalidateOnRefresh: "true",

        // scrub: "true",
        // end: "bottom 50%",
        // toggleActions: "play pause resume reset",
        // toggleActions: "restart complete reverse reset",
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    });

    gsap.from(".homeAboutDescription", {
      duration: 1,
      x: "-100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".homeAboutDescription",
        start: "top 80%",
        // end: "bottom 50%",
        // toggleActions: "restart complete reverse reset",
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    });
  });

  return (
    <div className="homeAboutBackground">
      <div className="homeAbout">
        <div className="homeAboutImg">
          {/* <img src={require("./Images/Trimurti Heights, Mumbai - AA & Associates.jpg")} alt="Img" /> */}
          <img src={require("./Images/01 Blue Heaven Infra - AA & Associates.jpg")} alt="Img" />
          <div className="homeAboutImgName">
            <h4>Blue Heaven Infra, Bhayandar</h4>
          </div>
        </div>
        <div className="homeAboutDescription">
          <h3>
            Welcome to <span>AA & Associates</span>
          </h3>
          <p>
            AA and Associates, a Structural and Post Tensioning Design Consultancy Company, is in business since 2003, located at Andheri (West),
            Mumbai to provide creative, innovative, optimal design of structures and a high quality service to our clients. With the design experience
            gained over years, we provide a professional, real-world solutions to the complex and multifaceted projects. Implemented successfully
            several hundreds of design projects like Residential, Industrial, Commercial, Malls, Institutional, Hotels including multi-storied and
            high rise buildings.
          </p>
          <div className="homeAboutReadMore">
            <NavLink to="/about-us" className="homeAboutReadMore1">
              Read more
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
