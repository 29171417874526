import React from "react";
import "./AllProjects.css";

const HoardingPics = [
  {
    url: require("./Projects-Images/7-Hoardings/01 Hoarding on CSI Airport Elevated Road, Bombay - AA & Associates.jpg"),
    name: "CSI Airport Elevated Road, Bombay",
  },
  {
    url: require("./Projects-Images/7-Hoardings/02 Hoarding on CSI Airport Elevated Road, Bombay - AA & Associates.jpg"),
    name: "CSI Airport Elevated Road, Bombay",
  },
  {
    url: require("./Projects-Images/7-Hoardings/03 Gantry Hoarding, Coimbatore - AA & Associates.jpg"),
    name: "Gantry Hoarding, Coimbatore",
  },
  {
    url: require("./Projects-Images/7-Hoardings/04 Gantry Hoarding at CSI Airport Elevated Road, Bombay - AA & Associates.jpg"),
    name: "CSI Airport Elevated Road, Bombay",
  },
  {
    url: require("./Projects-Images/7-Hoardings/05 Wall Mounted Hoarding near Arrivals at CSI Airport, Bombay - AA & Associates.jpg"),
    name: "Wall Mounted Hoarding near Arrivals at CSI Airport, Bombay",
  },
  {
    url: require("./Projects-Images/7-Hoardings/06 Display Hoardings, Coimbatore - AA & Associates.jpg"),
    name: "Display Hoardings, Coimbatore",
  },
  {
    url: require("./Projects-Images/7-Hoardings/07 Hoarding on CSI Airport Elevated Road, Bombay - AA & Associates.jpg"),
    name: "CSI Airport Elevated Road, Bombay",
  },
  {
    url: require("./Projects-Images/7-Hoardings/08 Dropdown Near Arrival at CSI Airport, Bombay - AA & Associates.jpg"),
    name: "Dropdown Near Arrival at CSI Airport, Bombay",
  },
  {
    url: require("./Projects-Images/7-Hoardings/09 Gantry Hoarding on CSI Airport Elevated Road, Bombay - AA & Associates.jpg"),
    name: "Gantry Hoarding on CSI Airport Elevated Road, Bombay",
  },
  {
    url: require("./Projects-Images/7-Hoardings/10 Electronic Display Hoardings, Coimbatore - AA & Associates.jpg"),
    name: "Electronic Display Hoardings, Coimbatore",
  },
  {
    url: require("./Projects-Images/7-Hoardings/11 Gantry Hoardings, Coimbatore - AA & Associates.jpg"),
    name: "Gantry Hoardings, Coimbatore",
  },
  {
    url: require("./Projects-Images/7-Hoardings/12 Bus Stop Displays, Bangalore - AA & Associates.jpg"),
    name: "Bus Stop Displays, Bangalore",
  },
];
const HoardingFlatPics = [
  {
    url: require("./Projects-Images/7-Hoardings/13 Dropdown in Premises of CSI Airport, Bombay - AA & Associates.jpg"),
    name: "Dropdown in Premises of CSI Airport, Bombay",
  },
  {
    url: require("./Projects-Images/7-Hoardings/14 Upstand Display near arrivals at CSI Airport, Bombay - AA & Associates.jpg"),
    name: "Upstand Display near arrivals at CSI Airport, Bombay",
  },
];

export default function Hoarding() {
  return (
    <div className="projectsContainer flat flat2" id="HO">
      <h2>Hoardings</h2>
      <div className="projectsWrap">
        {HoardingPics.map((HoardingPic) => {
          return (
            <div className="projects">
              <div className="projectsPhotos">
                <img src={HoardingPic.url} alt="Projects" />
                <div className="projectsNames">
                  <div className="projectsNamesContent">
                    <h4 className="projectsText">{HoardingPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* ---------------------------- Hoarding Flat-------------------------- */}
      <div className="HoardingFlatWrap">
        {HoardingFlatPics.map((HoardingFlatPic) => {
          return (
            <div className="HoardingFlat">
              <div className="HoardingFlatPhotos">
                <img src={HoardingFlatPic.url} alt="Projects" />
                <div className="HoardingFlatNames">
                  <div className="HoardingFlatNamesContent">
                    <h4>{HoardingFlatPic.name}</h4>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
