import React from "react";
import "./HomeClients.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";

const clientLogos = [
  {
    url: require("./Images/C1.jpg"),
  },
  {
    url: require("./Images/C2.png"),
  },
  {
    url: require("./Images/C3.png"),
  },
  {
    url: require("./Images/C4.jpg"),
  },
  {
    url: require("./Images/C5.jpg"),
  },
  {
    url: require("./Images/C6.jpg"),
  },
  {
    url: require("./Images/C7.png"),
  },
  {
    url: require("./Images/C8.png"),
  },
];

export default function HomeClients() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1750,
    pauseOnHover: false,
    arrows: true,
    className: "slidesClient",
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="ourClients">
      <h2>Our Clients</h2>
      <div className="ourClientsWrap">
        <Slider {...settings}>
          {clientLogos.map((clientLogo) => {
            return (
              <div className="ourClientsLogo">
                <img src={clientLogo.url} alt="Client Logo" />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="ourClientsViewMore">
        <NavLink to="/clients" className="ourClientsViewMore1">
          View More
        </NavLink>
      </div>
    </div>
  );
}
