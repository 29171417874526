import React from "react";
import "./App.css";
import NavbarRoute from "./Components/Navbar/NavbarRoute";
import Footer from "./Components/Footer/Footer";
import ArrowUp from './Components/ArrowUp'

function App() {
  return (
    <>
      <NavbarRoute />
      <ArrowUp showBelow={180} />
      <Footer />
    </>
  );
}


export default App;
