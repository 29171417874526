import React from "react";
import ResidentialTowers from "./ProjectsComponents/1-ResidentialTowers";
import Commercial from "./ProjectsComponents/2-Commercial";
import PublicBuildings from "./ProjectsComponents/3-PublicBuildings";
import ResidentialVillas from "./ProjectsComponents/4-ResidentialVillas";
import PT from "./ProjectsComponents/5-PT";
import MS from "./ProjectsComponents/6-MS";
import Hoardings from "./ProjectsComponents/7-Hoardings";
import Audit from "./ProjectsComponents/8-Audit";
import ProjectNav from "./ProjectsComponents/ProjectNav";
import RockAnchoring from "./ProjectsComponents/9-RockAnchoring";
import MEP from "./ProjectsComponents/10-MEP";

export default function Projects() {
  return (
    <>
      <ProjectNav />
      <ResidentialTowers />
      <Commercial />
      <PublicBuildings />
      <ResidentialVillas />
      <PT />
      <MS />
      <Hoardings />
      <Audit />
      <RockAnchoring />
      <MEP />
    </>
  );
}
