import React, { useEffect } from "react";
import "./WhoWeAre.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function WhoWeAre() {
  useEffect(() => {
    gsap.from(".WhoWeAreHeading", {
      duration: 1,
      x: "-100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".WhoWeAreHeading",
        start: "top 90%",
      },
    });
    gsap.from(".WhoWeAreContent", {
      duration: 1,
      x: "100",
      opacity: 0,
      ease: "ease-in",
      scrollTrigger: {
        trigger: ".WhoWeAreContent",
        start: "top 90%",
      },
    });
  });

  return (
    <>
      <div className="WhoWeAre">
        <div className="WhoWeAreWrap">
          <div className="WhoWeAreHeading">
            <h2>Who We Are</h2>
          </div>
          <div className="WhoWeAreContent">
            <p>
              &emsp;&emsp;&emsp;AA and Associates, a Structural and Post Tensioning Design Consultancy Company, is in business since 2003, located at
              Andheri (West), Mumbai to provide creative, innovative, optimal design of structures and a high quality service to our clients. With the
              design experience gained over years, we provide a professional, real-world solutions to the complex and multifaceted projects.
              Implemented successfully several hundreds of design projects like Residential, Industrial, Commercial, Malls, Institutional, Hotels
              including multi-storied and high rise buildings.
            </p>
            <p>
              &emsp;&emsp;&emsp;Our structural engineers are professionals in creating robust structural design encompassing stability, strength and
              rigidity of the structure onto which structures are built. Having designed many renowned structures across various sectors, the team has
              reached the new heights of expertise in engineering design and structural analysis.
            </p>
            <p>
              &emsp;&emsp;&emsp;A single point structural design consultancy where we focus on creative thinking to develop the solutions for the
              client’s needs as we believe in “care the clients to be in business”. Our technical expertise and thinking beyond the box has made us
              one of the leading structural designers firm.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
