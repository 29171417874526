import React from "react";
import "../../App.css";
import "./Home.css";
import Slide from "./HomeComponents/Slide";
import SlideMobile from "./HomeComponents/SlideMobile";
import HomeAbout from "./HomeComponents/HomeAbout";
import HomeServices from "./HomeComponents/HomeServices";
import HomeClients from "./HomeComponents/HomeClients";
// import HomeClientsTab from "./HomeComponents/HomeClientsTab";

export default function Home() {
  return (
    <>
      <div className="slideDesktop">
        <Slide />
      </div>
      <div className="slideMobile">
        <SlideMobile />
      </div>
      <HomeAbout />
      <HomeServices />
      <HomeClients />
      {/* <HomeClientsTab /> */}
    </>
  );
}
