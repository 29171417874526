import React from "react";
import "./Map.css";

export default function Map() {
  return (
    <div className="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d471.21714586651115!2d72.839267!3d19.119183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaee2a60ab2973a85!2sAA%20%26%20Associates!5e0!3m2!1sen!2sin!4v1604859427704!5m2!1sen!2sin" 
        title="Map"
      ></iframe>
    </div>
  );
}