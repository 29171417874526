import React from "react";
import ServicesDesc from "./ServicesComponents/ServicesDesc";

export default function Services() {
  return (
    <>
      <ServicesDesc />
    </>
  );
}
