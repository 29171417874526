import React from "react";
import "./ContactBackground.css";

export default function ContactBackground() {
  return (
    <div className="backgroundContactWrap">
      <div className="backgroundContact">
        <div className="backgroundContactContainer"></div>
      </div>
      <div className="backgroundContactContent">
        <h1>Got a question?</h1>
        <p> We'd love to talk about how we can help you.</p>
      </div>
      <div className="wave"></div>
    </div>
  );
}
